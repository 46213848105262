import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';

import FeatureImg1 from "../assets/images/features-details-image/feature1.png";
import Physics from "../assets/images/physics.png";
import FeatureImg2 from "../assets/images/features-details-image/feature2.png";
import systemdynamics from "../assets/images/systemdynamics.png";

const Simulation = () => (
    <Layout>
        <SEO title="Simulation & Modeling" /> 

        <Navbar />

        <div className="features-details-area ptb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 features-details-image">
                        <img 
                            src={Physics} 
                            className="animate__animated animate__fadeInUp" 
                            alt="image" 
                        />
                    </div>

                    <div className="col-lg-6 features-details">
                        <div className="features-details-desc">
                            <h3>Simulation & Modeling</h3>
                            <p>Our team is trained in developing many types of simulation models to meet your needs. These simulations allow for better understanding systems or products, and the results of these models can further inform decision making and product design.</p>

                            <ul className="features-details-list">
                                <li>System Dynamics Models</li>
                                <li>Agent-Based Simulations</li>
                                <li>Discrete Event Simulation & Network Models</li>
                                <li>Advanced physics simulation and modeling using FEA, CFD, EMAG, and thermodynamic system modeling</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="separate"></div>

                <div className="row align-items-center">
                    <div className="col-lg-6 features-details">
                        <div className="features-details-desc">
                            <p> Simulation plays a key role in understanding and designing for complex systems. Modeling and simulation lie at the heart of Faraya's approach to designing, developing, and fine-tuning world-class products, systems, and solutions. </p>

                            <div className="features-details-accordion">
                                <Accordion allowZeroExpanded preExpanded={['a']}>
                                    <AccordionItem uuid="a">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                   What Simulation Tools Do You Use?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            We use ANSYS for engineering simulation and 3D design. For agent-based, discrete event, and system dynamics models, we use a mix of VenSim, and Python packages.                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="b">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                    How Are the Model Results Provided?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            The results of our simulation models are always presented as visual models that can be interacted with. Our clients will be able to change the assumptions and inputs of these models, and dynamically receive new insights in real-time.                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="c">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                   How Long Does it Take?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Timeline will depend on the scope of the model and the data available. Our team can assess your needs at the onset of a project and articulate a timeline accordingly.                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-6 features-details-image">
                        <img 
                            src={systemdynamics} 
                            className="animate__animated animate__fadeInUp" 
                            alt="image" 
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
)

export default Simulation;
